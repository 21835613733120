@import 'bootstrap/dist/css/bootstrap.min.css';

$allOtdrsBrndColorA: "#467E82";
$allOtdrsBrndColorB: "#468263";

$mediaMinSizes: (
  micro: 0px,
  small: 920px,
  medium: 1080px,
  large: 1420px,
  x-large: 1620px
);
$navHeight: (
  micro: 0em,
  small: 0em,
  medium: 0em,
  large: 0em,
  x-large: 0em
);
$pageMargin: (
  micro: 0em,
  small: 1em,
  medium: 2em,
  large: 3em,
  x-large: 4em
);

.mainContainer {
  width: 100%;
  height: 100%;
  @each $sizeName, $size in $mediaMinSizes {
    @media (min-width: $size) {
      margin-top: map-get($navHeight, $sizeName);
      padding-left: map-get($pageMargin, $sizeName);
      padding-right: map-get($pageMargin, $sizeName);
    }
  }
}

.profileContainer {
  width: 100%;
  height: 100%;
  
  .profileHeader {
    width: 100%;
    height: 25em;
    position: relative;

    .bannerImage {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .profileImage {
      position: absolute;
      object-fit: cover;
      width: 12em;
      height: 12em;
      left: 2%;
      bottom: -5em;
    }
  }

  .profileData {
    margin-top: 6em;
    width: 100%;
    float: left;
    p {
      text-align: left;
      margin-bottom: 1em;
    }
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.menu-link {
  color: black;
  text-decoration: none; /* no underline */
}

.App-link {
  color: #61dafb;
}

input {
  height: 40;
  margin: 12;
  border-width: 1;
  padding: 5;
  border-radius: 100;
}

.sign-out-btn {
  margin-left: 10px
}

container {
  flex: 1;
  justify-content: 'center';
  align-content: 'center';
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
